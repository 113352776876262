import { ApplicantType } from '@shared/enums';
import { TabBasic } from '@shared/ui/tabs/TabBasic';
import { signUpPersonTabsData } from '@widgets/signUp/constants';
import React, { useCallback, useMemo } from 'react';
import { useSignUpPersonContext } from './SignUpPersonContext';

export const SignUpPersonTabs = () => {
  const { applicantType, setApplicantType, phoneNumber } = useSignUpPersonContext();

  const handleTabChange = useCallback(
    (key: string) => {
      setApplicantType(key as ApplicantType);
    },
    [setApplicantType],
  );

  const tabList = useMemo(
    () =>
      signUpPersonTabsData.map((item) => ({
        ...item,
        isActive: item.eventKey === applicantType,
      })),
    [applicantType],
  );

  if (phoneNumber) return null;

  return (
    <ul className="flex space-x-4 mt-10 md:mt-[50px] md:justify-center md:space-x-8">
      {tabList.map((item) => (
        <TabBasic key={item.eventKey} {...item} onClick={handleTabChange} />
      ))}
    </ul>
  );
};
