import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserType } from '@shared/enums';

import { OauthDivider } from './OauthDivider';
import { IdGovUaButton } from './IdGovUaConnect';
import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';

interface OauthProvidersProps {
  type: UserType.InNeed | UserType.Volunteer;
}

export const OauthProviders: React.FC<OauthProvidersProps> = ({ type }) => {
  const { t } = useTranslation();
  const isLanguageUk = useIsSpecificLanguage('uk');

  if (!isLanguageUk) {
    return null;
  }

  return (
    <div className="max-w-[528px] mx-auto">
      <div className="flex justify-center">
        <IdGovUaButton text={t('continue-with')} type={type} />
      </div>

      <div>
        <OauthDivider />
      </div>
    </div>
  );
};
