import { CustomerAddress, VolunteerAddress } from '@store/api/types';

import { ApplicantType } from '@shared/enums';

// TODO: remove/refactor to object args and refactor this code

interface CreateUser {
  contactName: string;
  phoneNumber: string;
  password: string;
  email?: string | null;
  avatar?: Blob | null;
}

interface CreatePrivateInNeedUser extends CreateUser {
  address: CustomerAddress;
  applicantType: ApplicantType;
  description: string;
  groups: number[];
}

interface CreateLegalInNeedUser extends CreateUser {
  address: CustomerAddress;
  legalName: string;
  regCode: string;
}

interface CreatePrivateVolunteerUser extends CreateUser {
  address: VolunteerAddress;
  applicantType: ApplicantType;
}

interface CreateLegalVolunteerUser extends CreateUser {
  legalName: string;
  address: VolunteerAddress;
  regCode: string;
}

function appendAddress(formData: FormData, obj: VolunteerAddress | CustomerAddress) {
  for (const key of Object.keys(obj)) {
    // @ts-ignore
    formData.append(`address[${key}]`, String(obj[key]));
  }
}

export function createPrivateVolunteerUser(data: CreatePrivateVolunteerUser) {
  const formData = new FormData();

  formData.append('contactName', data.contactName);
  formData.append('phoneNumber', data.phoneNumber);
  formData.append('applicantType', data.applicantType);
  formData.append('password', data.password);

  appendAddress(formData, data.address);

  if (data.avatar) {
    formData.append('avatar', data.avatar);
  }

  if (data.email) {
    formData.append('email', data.email);
  }

  return formData;
}

export function createLegalVolunteerUser(data: CreateLegalVolunteerUser) {
  const formData = new FormData();

  formData.append('contactName', data.contactName);
  formData.append('legalName', data.legalName);
  formData.append('phoneNumber', data.phoneNumber);

  formData.append('applicantType', ApplicantType.LegalEntity);
  formData.append('password', data.password);
  formData.append('regCode', data.regCode);

  appendAddress(formData, data.address);

  if (data.avatar) {
    formData.append('avatar', data.avatar);
  }

  if (data.email) {
    formData.append('email', data.email);
  }

  return formData;
}

export function createPrivateInNeedUser(data: CreatePrivateInNeedUser) {
  const formData = new FormData();

  formData.append('contactName', data.contactName);
  formData.append('phoneNumber', data.phoneNumber);

  formData.append('applicantType', data.applicantType);
  formData.append('password', data.password);
  formData.append('description', data.description);

  appendAddress(formData, data.address);

  for (const group of data.groups) {
    formData.append('groups[]', String(group));
  }

  if (data.avatar) {
    formData.append('avatar', data.avatar);
  }

  if (data.email) {
    formData.append('email', data.email);
  }

  return formData;
}

export function createLegalInNeedUser(data: CreateLegalInNeedUser) {
  const formData = new FormData();

  formData.append('contactName', data.contactName);
  formData.append('phoneNumber', data.phoneNumber);
  formData.append('legalName', data.legalName);
  formData.append('regCode', data.regCode);

  formData.append('applicantType', ApplicantType.LegalEntity);
  formData.append('password', data.password);

  appendAddress(formData, data.address);

  if (data.avatar) {
    formData.append('avatar', data.avatar);
  }

  if (data.email) {
    formData.append('email', data.email);
  }

  return formData;
}
