import React from 'react';

import { UserType } from '@shared/enums';
import { OauthProviders } from '@widgets/auth/OauthProviders';
import { SignUpPersonProvider } from '@widgets/signUp/SignUpPersonContext';
import { SignUpRegisterTitle } from '@widgets/signUp/SignUpRegisterTitle';
import { SignUpVolunteer } from '@widgets/signUp/signUpVolunteer/SignUpVolunteer';

const SignUpVolunteerPage = () => {
  return (
    <SignUpPersonProvider>
      <div className="px-4 pt-6 pb-12 lg:pt-12">
        <SignUpRegisterTitle />
        <OauthProviders type={UserType.Volunteer} />
        <SignUpVolunteer />
      </div>
    </SignUpPersonProvider>
  );
};

export default SignUpVolunteerPage;
