import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@shared/ui/buttons/Button';
import { SignUpAlreadyRegistered } from '@widgets/signUp/SignUpAlreadyRegistered';

interface SignUpFormControls {
  multiStep?: boolean;
  isLastStep?: boolean;
  nextStepDisabled?: boolean;
  submitDisabled: boolean;
  onNextButtonClick?: () => void;
  onBackButtonClick: () => void;
}

export const SignUpFormControls: React.FC<SignUpFormControls> = (props) => {
  const { t } = useTranslation();
  const {
    multiStep = false,
    isLastStep = false,
    nextStepDisabled = true,
    submitDisabled = true,
    onNextButtonClick,
    onBackButtonClick,
  } = props;

  return (
    <div className="flex flex-col items-center mt-8">
      {multiStep && !isLastStep && (
        <Button as="button" variant="primary" className="" disabled={nextStepDisabled} onClick={onNextButtonClick}>
          {t('futher')}
        </Button>
      )}
      {(!multiStep || (multiStep && isLastStep)) && (
        <Button as="button" variant="primary" type="submit" disabled={submitDisabled}>
          {t('sign-up')}
        </Button>
      )}

      <Button as="button" variant="secondary" className="mt-2" onClick={onBackButtonClick}>
        {t('back')}
      </Button>

      <SignUpAlreadyRegistered className="mt-10" />
    </div>
  );
};
