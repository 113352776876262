import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { createLegalVolunteerUser } from '@services/users';
import { ApplicantType, Routes } from '@shared/enums';
import { SignUpFormControls } from '@shared/signUp/SignUpFormControls';
import { CheckboxField } from '@shared/ui/CheckboxField';
import { CountryDropdown } from '@shared/ui/dropdowns/CountryDropdown';
import { ErrorList } from '@shared/ui/ErrorList';
import { TextField } from '@shared/ui/TextField';
import { isApiError, translateApiError } from '@shared/utils/errors';
import { useSignUpVolunteerMutation } from '@store/api/user.api';
import { SignUpApproveProcessLabel } from '@widgets/signUp/SignUpApproveProcessLabel';
import { FIELD_APPROVE } from '@widgets/signUp/signUpInNeed/signUpInNeedPrivatePerson/constants';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLogIn } from '@hooks/useLogIn';
import { useNavigate } from '@hooks/useNavigate';
import { useCabinetNavigate } from '@shared/hooks/useCabinetNavigate';
import { PhoneInput, usePhoneInnerValidation } from '@shared/ui/PhoneInput/PhoneInput';
import { useSignUpPersonContext } from '../../SignUpPersonContext';
import { FIELD_PASSWORD } from '../signUpVolunteerPrivatePerson/constants';
import {
  FIELD_AVATAR,
  FIELD_CONTACT_NAME,
  FIELD_COUNTRY,
  FIELD_EMAIL,
  FIELD_LEGAL_NAME,
  FIELD_PHONE,
  FIELD_REG_CODE,
} from './constants';
import { useGetValidationSchemaVolunteerLegalPerson } from './useGetValidationSchemaVolunteerLegalPerson';
import { AvatarFormInput } from '@shared/ui/avatar/AvatarFormInput';

const initialFormValues = {
  [FIELD_AVATAR]: null,
  [FIELD_LEGAL_NAME]: '',
  [FIELD_REG_CODE]: '',
  [FIELD_EMAIL]: '',
  [FIELD_CONTACT_NAME]: '',
  [FIELD_COUNTRY]: 'ua',
  [FIELD_PHONE]: '',
  [FIELD_PASSWORD]: '',
  [FIELD_APPROVE]: false,
};

export const SignUpVolunteerLegalPerson = () => {
  const { applicantType } = useSignUpPersonContext();
  const [signUpVolunteer] = useSignUpVolunteerMutation();
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const { t } = useTranslation();

  const { getPhoneInnerValidationResult, setPhoneInnerValidationResult } = usePhoneInnerValidation();
  const [label, setLabel] = useState('usreou-code');
  const validationSchema = useGetValidationSchemaVolunteerLegalPerson();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
    context: {
      getPhoneInnerValidationResult,
    },
  });
  const {
    handleSubmit,
    control,
    watch,
    getValues,
    reset,
    formState: { isSubmitting, isValidating, errors },
  } = methods;

  const cabinetNavigate = useCabinetNavigate();
  const logIn = useLogIn();

  useEffect(() => {
    setLabel(getValues(FIELD_COUNTRY) === 'ua' ? 'usreou-code' : 'Tax Number');
  }, [watch(FIELD_COUNTRY)]);

  const handleBackButtonClick = () => {
    navigate(Routes.AuthSignUp);
  };

  const onSubmit = async (data: any) => {
    const { legalName, avatar, contactName, regCode, country, phone, password, email } = data;
    const volunteer = createLegalVolunteerUser({
      legalName,
      contactName,
      phoneNumber: phone,
      address: { country },
      regCode,
      password,
      email,
      avatar,
    });

    try {
      const { token, payload } = await signUpVolunteer(volunteer).unwrap();
      logIn(payload, token);
      cabinetNavigate(payload.type);
      reset();
    } catch (err) {
      if (isApiError(err)) {
        setApiErrors(translateApiError(err, t));
      }

      // TODO: handle bad errors
    }
  };

  if (applicantType !== ApplicantType.LegalEntity) return null;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-[528px] mx-auto">
        <div className="sign-up-form-card">
          <AvatarFormInput />

          <Controller
            name={FIELD_COUNTRY}
            control={control}
            render={({ field: { value, onChange } }) => (
              <CountryDropdown
                value={value as string}
                errorText={errors[FIELD_COUNTRY]?.message}
                onChange={onChange}
                className="mt-10"
              />
            )}
          />

          <TextField id={FIELD_LEGAL_NAME} label="company-name" className="mt-10" />
          <TextField id={FIELD_REG_CODE} label={label} className="mt-10" />
          <TextField id={FIELD_CONTACT_NAME} label="full-name-of-contact-person" className="mt-10" />

          <Controller
            name={FIELD_PHONE}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <PhoneInput
                  value={value}
                  onChange={onChange}
                  className="mt-10"
                  errorText={errors[FIELD_PHONE]?.message}
                  setPhoneInnerValidationResult={setPhoneInnerValidationResult}
                />
              );
            }}
          />

          <TextField id={FIELD_EMAIL} type="email" label="email" className="mt-10" />
          <TextField id={FIELD_PASSWORD} type="password" label="password" className="mt-10" />

          <ErrorList errors={apiErrors} className="mt-6" />
        </div>
        <CheckboxField id={FIELD_APPROVE} label={<SignUpApproveProcessLabel />} className="mt-6 mx-6 md:mx-14" />
        <SignUpFormControls submitDisabled={isSubmitting || isValidating} onBackButtonClick={handleBackButtonClick} />
      </form>
    </FormProvider>
  );
};
