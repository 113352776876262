import React from 'react';
import { useTranslation } from 'react-i18next';

export const OauthDivider = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center my-10 after:block after:grow after:h-[1px] after:bg-black after:ml-5 before:block before:grow before:h-[1px] before:mr-5 before:bg-black">
      {t('or').toUpperCase()}
    </div>
  );
};
