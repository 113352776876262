import React from 'react';
import { useTranslation } from 'react-i18next';

import { OuterLink } from '@shared/ui/GenericLink';

export const SignUpApproveProcessLabel = () => {
  const { t } = useTranslation();

  return (
    <>
      {t('process-data-agreement')}{' '}
      <OuterLink className="text-shamrock hover:underline" to="/documents/privacy-policy.pdf">
        {t('conditions')}
      </OuterLink>
    </>
  );
};
