import { SIGN_UP } from '@shared/constants';
import React from 'react';
import { SignUpPersonTabs } from '../SignUpPersonTabs';
import { SignUpVolunteerLegalPerson } from './signUpVolunteerLegalPerson/SignUpVolunteerLegalPerson';
import { SignUpVolunteerPrivatePerson } from './signUpVolunteerPrivatePerson/SignUpVolunteerPrivatePerson';

export const SignUpVolunteer = () => {
  return (
    <>
      <SignUpPersonTabs />
      <SignUpVolunteerLegalPerson />
      <SignUpVolunteerPrivatePerson />
    </>
  );
};
